// extracted by mini-css-extract-plugin
export var button = "styles-module--button--e6a8a";
export var colorClearWhite = "styles-module--colorClearWhite--cd1a1";
export var colorDarkGrey = "styles-module--colorDarkGrey--43d64";
export var colorGreen = "styles-module--colorGreen--37732";
export var colorInherit = "styles-module--colorInherit--1bd39";
export var isOutlined = "styles-module--isOutlined--0aab8";
export var sizeLarge = "styles-module--sizeLarge--143ac";
export var sizeMedium = "styles-module--sizeMedium--94c40";
export var sizeSmall = "styles-module--sizeSmall--9066a";
export var variantButton = "styles-module--variantButton--0d3f4";
export var variantText = "styles-module--variantText--94d16";