// extracted by mini-css-extract-plugin
export var greenHrColor = "styles-module--greenHrColor--a0820";
export var margin = "styles-module--margin--fb77e";
export var padding = "styles-module--padding--9a321";
export var responsive = "styles-module--responsive--ae7e8";
export var spacer = "styles-module--spacer--a8531";
export var topOnly = "styles-module--topOnly--7299d";
export var xLg = "styles-module--xLg--592cc";
export var xMd = "styles-module--xMd--b2258";
export var xSm = "styles-module--xSm--9b3c3";
export var xXl = "styles-module--xXl--59809";
export var xXs = "styles-module--xXs--2b251";
export var yLg = "styles-module--yLg--6f8bd";
export var yMd = "styles-module--yMd--c06ab";
export var ySm = "styles-module--ySm--1de05";
export var yXl = "styles-module--yXl--b3861";
export var yXs = "styles-module--yXs--6ca3b";