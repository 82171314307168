// extracted by mini-css-extract-plugin
export var colorBase = "styles-module--colorBase--29a2f";
export var colorBlack = "styles-module--colorBlack--5249a";
export var colorDarkGrey = "styles-module--colorDarkGrey--601ca";
export var colorGreen = "styles-module--colorGreen--11daf";
export var colorGrey = "styles-module--colorGrey--67978";
export var sizeLarge = "styles-module--sizeLarge--a2d21";
export var sizeNone = "styles-module--sizeNone--6b394";
export var sizeRegular = "styles-module--sizeRegular--b880c";
export var text = "styles-module--text--1306a";
export var weight100 = "styles-module--weight100--8a3e1";
export var weight200 = "styles-module--weight200--8ec14";
export var weight300 = "styles-module--weight300--59d8c";
export var weight400 = "styles-module--weight400--8371a";
export var weight500 = "styles-module--weight500--aabc2";
export var weight600 = "styles-module--weight600--ca700";
export var weight700 = "styles-module--weight700--0d25b";
export var weight800 = "styles-module--weight800--feec5";
export var weightBold = "styles-module--weightBold--61fac";