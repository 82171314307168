// extracted by mini-css-extract-plugin
export var colorBase = "styles-module--colorBase--e7fdf";
export var colorBlack = "styles-module--colorBlack--e38d0";
export var colorDarkGrey = "styles-module--colorDarkGrey--2ff8e";
export var colorGreen = "styles-module--colorGreen--b561c";
export var colorGrey = "styles-module--colorGrey--39c1c";
export var colorWhite = "styles-module--colorWhite--d8dbc";
export var heading = "styles-module--heading--85812";
export var variantH1 = "styles-module--variantH1--77423";
export var variantH2 = "styles-module--variantH2--77040";
export var variantH3 = "styles-module--variantH3--e755f";
export var variantH4 = "styles-module--variantH4--b3cc9";
export var variantH5 = "styles-module--variantH5--917a8";
export var variantH6 = "styles-module--variantH6--51e32";
export var weight100 = "styles-module--weight100--4917b";
export var weight200 = "styles-module--weight200--1fd1c";
export var weight300 = "styles-module--weight300--7654f";
export var weight400 = "styles-module--weight400--69772";
export var weight500 = "styles-module--weight500--94827";
export var weight600 = "styles-module--weight600--57282";
export var weight700 = "styles-module--weight700--0e79a";
export var weight900 = "styles-module--weight900--0189d";
export var weightBold = "styles-module--weightBold--58f5f";