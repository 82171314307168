import React from "react"
import * as styles from "./styles.module.scss"
import { Anchor } from "@components/atoms"
import { PrismicLink } from "@prismicio/react"
import { useSiteMetadata } from "@lib/hooks/useSiteData"
import classNames from "classnames"

const SocialIcon = ({ icon_type, baseColor = true }) => {
  return (
    <>
      {icon_type === "facebook" && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="19"
          viewBox="0 0 18 19"
          fill="none">
          <path
            d="M15.625 0.75H1.875C0.820312 0.75 0 1.60938 0 2.625V16.375C0 17.4297 0.820312 18.25 1.875 18.25H7.22656V12.3125H4.76562V9.5H7.22656V7.39062C7.22656 4.96875 8.67188 3.60156 10.8594 3.60156C11.9531 3.60156 13.0469 3.79688 13.0469 3.79688V6.17969H11.8359C10.625 6.17969 10.2344 6.92188 10.2344 7.70312V9.5H12.9297L12.5 12.3125H10.2344V18.25H15.625C16.6406 18.25 17.5 17.4297 17.5 16.375V2.625C17.5 1.60938 16.6406 0.75 15.625 0.75Z"
            fill={baseColor ? "#4267B2" : "currentColor"}
          />
        </svg>
      )}
      {icon_type === "twitter" && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="19"
          viewBox="0 0 18 19"
          fill="none">
          <path
            d="M15.625 0.75H1.875C0.820312 0.75 0 1.60938 0 2.625V16.375C0 17.4297 0.820312 18.25 1.875 18.25H15.625C16.6406 18.25 17.5 17.4297 17.5 16.375V2.625C17.5 1.60938 16.6406 0.75 15.625 0.75ZM13.7109 6.96094C13.7109 7.07812 13.7109 7.19531 13.7109 7.3125C13.7109 10.6719 11.1328 14.5781 6.40625 14.5781C4.96094 14.5781 3.63281 14.1875 2.5 13.4453C2.69531 13.4844 2.89062 13.4844 3.08594 13.4844C4.29688 13.4844 5.39062 13.0547 6.28906 12.3906C5.15625 12.3516 4.21875 11.6094 3.86719 10.5938C4.29688 10.6719 4.64844 10.6719 5.03906 10.5547C3.86719 10.3203 3.00781 9.30469 3.00781 8.05469V8.01562C3.32031 8.21094 3.71094 8.32812 4.14062 8.32812C3.4375 7.85938 3.00781 7.07812 3.00781 6.21875C3.00781 5.71094 3.125 5.28125 3.35938 4.89062C4.60938 6.45312 6.52344 7.46875 8.63281 7.58594C8.28125 5.86719 9.57031 4.42188 11.1328 4.42188C11.875 4.42188 12.5391 4.73438 13.0078 5.24219C13.5938 5.125 14.1406 4.92969 14.6484 4.61719C14.4531 5.24219 14.0234 5.71094 13.5156 6.02344C14.0234 5.98438 14.5312 5.82812 15 5.63281C14.6484 6.14062 14.1797 6.60938 13.7109 6.96094Z"
            fill={baseColor ? "#1DA1F2" : "currentColor"}
          />
        </svg>
      )}
      {icon_type === "instagram" && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="19"
          height="19"
          viewBox="0 0 19 19"
          fill="none">
          <path
            d="M9.75 5.00781C7.25 5.00781 5.25781 7.03906 5.25781 9.5C5.25781 12 7.25 13.9922 9.75 13.9922C12.2109 13.9922 14.2422 12 14.2422 9.5C14.2422 7.03906 12.2109 5.00781 9.75 5.00781ZM9.75 12.4297C8.14844 12.4297 6.82031 11.1406 6.82031 9.5C6.82031 7.89844 8.10938 6.60938 9.75 6.60938C11.3516 6.60938 12.6406 7.89844 12.6406 9.5C12.6406 11.1406 11.3516 12.4297 9.75 12.4297ZM15.4531 4.85156C15.4531 4.26562 14.9844 3.79688 14.3984 3.79688C13.8125 3.79688 13.3438 4.26562 13.3438 4.85156C13.3438 5.4375 13.8125 5.90625 14.3984 5.90625C14.9844 5.90625 15.4531 5.4375 15.4531 4.85156ZM18.4219 5.90625C18.3438 4.5 18.0312 3.25 17.0156 2.23438C16 1.21875 14.75 0.90625 13.3438 0.828125C11.8984 0.75 7.5625 0.75 6.11719 0.828125C4.71094 0.90625 3.5 1.21875 2.44531 2.23438C1.42969 3.25 1.11719 4.5 1.03906 5.90625C0.960938 7.35156 0.960938 11.6875 1.03906 13.1328C1.11719 14.5391 1.42969 15.75 2.44531 16.8047C3.5 17.8203 4.71094 18.1328 6.11719 18.2109C7.5625 18.2891 11.8984 18.2891 13.3438 18.2109C14.75 18.1328 16 17.8203 17.0156 16.8047C18.0312 15.75 18.3438 14.5391 18.4219 13.1328C18.5 11.6875 18.5 7.35156 18.4219 5.90625ZM16.5469 14.6562C16.2734 15.4375 15.6484 16.0234 14.9062 16.3359C13.7344 16.8047 11 16.6875 9.75 16.6875C8.46094 16.6875 5.72656 16.8047 4.59375 16.3359C3.8125 16.0234 3.22656 15.4375 2.91406 14.6562C2.44531 13.5234 2.5625 10.7891 2.5625 9.5C2.5625 8.25 2.44531 5.51562 2.91406 4.34375C3.22656 3.60156 3.8125 3.01562 4.59375 2.70312C5.72656 2.23438 8.46094 2.35156 9.75 2.35156C11 2.35156 13.7344 2.23438 14.9062 2.70312C15.6484 2.97656 16.2344 3.60156 16.5469 4.34375C17.0156 5.51562 16.8984 8.25 16.8984 9.5C16.8984 10.7891 17.0156 13.5234 16.5469 14.6562Z"
            fill={baseColor ? "#833AB4" : "currentColor"}
          />
        </svg>
      )}

      {icon_type === "tiktok" && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="19"
          height="19"
          viewBox="0 0 25 29"
          fill="none">
          <path
            d="M24.5 11.9844V7.22656C24.4453 7.22656 24.4453 7.22656 24.4453 7.22656C23.1328 7.22656 21.875 6.84375 20.8359 6.13281C19.3047 5.14844 18.2109 3.5625 17.8828 1.75781C17.7734 1.375 17.7734 0.992188 17.7734 0.609375C17.7734 0.554688 17.7734 0.554688 17.7734 0.5H12.9609V19.6406C12.9609 21.8828 11.1016 23.7422 8.85938 23.7422C6.61719 23.7422 4.75781 21.8828 4.75781 19.6406C4.75781 17.3984 6.61719 15.5391 8.85938 15.5391C9.29688 15.5391 9.67969 15.5938 10.1172 15.7578V10.8359C9.67969 10.7812 9.29688 10.7266 8.85938 10.7266C3.9375 10.7266 0 14.7188 0 19.6406C0 24.5625 3.9375 28.5 8.85938 28.5C13.7812 28.5 17.7734 24.5625 17.7734 19.6406V9.85156C19.6328 11.2188 21.9297 11.9844 24.4453 11.9844H24.5Z"
            fill={baseColor ? "#000000" : "currentColor"}
          />
        </svg>
      )}

      {icon_type === "linkedin" && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="19"
          height="27"
          viewBox="0 0 27 27"
          fill="none">
          <path
            d="M24.375 0.625H1.81641C0.820312 0.625 0 1.50391 0 2.55859V25C0 26.0547 0.820312 26.875 1.81641 26.875H24.375C25.3711 26.875 26.25 26.0547 26.25 25V2.55859C26.25 1.50391 25.3711 0.625 24.375 0.625ZM7.91016 23.125H4.04297V10.6445H7.91016V23.125ZM5.97656 8.88672C4.6875 8.88672 3.69141 7.89062 3.69141 6.66016C3.69141 5.42969 4.6875 4.375 5.97656 4.375C7.20703 4.375 8.20312 5.42969 8.20312 6.66016C8.20312 7.89062 7.20703 8.88672 5.97656 8.88672ZM22.5 23.125H18.5742V17.0312C18.5742 15.625 18.5742 13.75 16.582 13.75C14.5312 13.75 14.2383 15.332 14.2383 16.9727V23.125H10.3711V10.6445H14.0625V12.3438H14.1211C14.6484 11.3477 15.9375 10.293 17.8125 10.293C21.7383 10.293 22.5 12.9297 22.5 16.2695V23.125Z"
            fill={baseColor ? "#0077b5" : "currentColor"}
          />
        </svg>
      )}

      {icon_type === "youtube" && (
        <svg
          width="29"
          height="19"
          viewBox="0 0 33 23"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M32.168 4.07422C31.8164 2.66797 30.7031 1.55469 29.3555 1.20312C26.8359 0.5 16.875 0.5 16.875 0.5C16.875 0.5 6.85547 0.5 4.33594 1.20312C2.98828 1.55469 1.875 2.66797 1.52344 4.07422C0.820312 6.53516 0.820312 11.8086 0.820312 11.8086C0.820312 11.8086 0.820312 17.0234 1.52344 19.543C1.875 20.9492 2.98828 22.0039 4.33594 22.3555C6.85547 23 16.875 23 16.875 23C16.875 23 26.8359 23 29.3555 22.3555C30.7031 22.0039 31.8164 20.9492 32.168 19.543C32.8711 17.0234 32.8711 11.8086 32.8711 11.8086C32.8711 11.8086 32.8711 6.53516 32.168 4.07422ZM13.5938 16.5547V7.0625L21.9141 11.8086L13.5938 16.5547Z"
            fill={baseColor ? "#FF0000" : "currentColor"}
          />
        </svg>
      )}
    </>
  )
}
export const Social = ({ baseColors = true, ...props }) => {
  const siteData = useSiteMetadata()

  if (!siteData?.social) {
    return <></>
  }

  return (
    <div className={classNames(styles.social, props.className)}>
      {siteData?.social.map(({ social_link, social_icon }, key) => {
        const Component = ({ children, ...props }) => (
          <Anchor {...props}>{children}</Anchor>
        )
        return (
          <PrismicLink
            key={key}
            field={social_link}
            externalComponent={Component}
            internalComponent={Component}>
            <SocialIcon baseColor={baseColors} icon_type={social_icon} />
          </PrismicLink>
        )
      })}
    </div>
  )
}

export default Social
